import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Card, Divider, Button, Input, Modal, Tabs, Select, Drawer, message, Checkbox, Form, Spin } from "antd";
import { SearchOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, CloseOutlined, SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import { htmlPrint } from "../../../../lib/printer/htmlPrinter";
import Close from "../../../../assets/images/close-x.svg";
import User from "../../../../assets/images/User.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import db from "../../../../database";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";

// GLOBAL CONSTANTS START
const TabPane = Tabs.TabPane;
const { Option } = Select;
// GLOBAL CONSTANTS END

const PointOfSuperMarketModals = (props) => {
  const {
    cart,
    inputFocused,
    setInputFocused,
    displayOrderHistory,
    setDisplayOrderHistory,
    searchOrderHistory,
    showOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    selectParkedBill,
    displaySetOrderType,
    setDisplayOrderType,
    posSaleTypes,
    changeOrderType,
    displayOfferProductSelectiton,
    setDisplayOfferProductSelection,
    offerProductsList,
    selectOfferProduct,
    displayBatchSelection,
    setDisplayBatchSelection,
    batchSetAvailable,
    selectProductToCart,
    displayManualQtyWeightInput,
    setDisplayManualQtyWeightInput,
    currentWeightSelectedProduct,
    productWeightModalInput,
    onProductModalChangeWeight,
    addManualWeightToProduct,
    handleWeightManual,
    couponModalVisible,
    closeCouponModal,
    checkCoupon,
    setCouponInput,
    setLoyaltyInputValue,
    loyaltyInputValue,
    couponInput,
    handleCouponInput,
    handleLoyalityInput,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    loyaltyPaymentOtp,
    checkLoyality,
    processOtpInput,
    setLoyaltyPaymentOtp,
    // OMS Order MOdal and Side menu drawer//,
    displayOMSOrderItemsModal,
    omsOrderStatus,
    selectedOMSOrderStatus,
    setSelectedOrder,
    handleOMSOrderStatusSelection,
    order,
    handleOrderSearchInput,
    searchOrders,
    selectedOrder,
    handleOrderCard,
    tillData,
    handleOmsOrders,
    setDisplayOMSOrderItemsModal,
    handleOMSStatusButton,
    omsOrderTotalPrice,
    onClose,
    sideMenuDrawervisible,
    setSideMenuDrawervisible,
    salesRepModalOpen,
    setSalesRepModalOpen,
    handleSalesRepresentive,
    salesRepresentSearchInput,
    setSalesRepresentSearchInput,
    handleSalesRepresentSearchInput,
    filteredSalesRepresentList,

    manualDiscountModalVisible,
    setManualDiscountModalVisible,
    manualDiscountInput,
    setManualDiscountInput,
    manualDiscountTypes,
    setManualDiscountTypes,
    handleManualDiscountKeyPress,
    applyManualDiscount,
    selectedManualDiscountType,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    setEnableManualDiscountInput,
    removeAllDiscounts,
    setProductWeightModalInput,
    // Paytm QR Code //
    paytmQrCodeModalOpens,
    setPaytmQrCodeModalOpens,
    qrCodeResponse,
    handleVerifyPayment,

    handleSalesReturnFromSideMenu,
    posConfig,
    // Cash management
    cashIn,
    pettCashIn,
    onChangeCheckbox,
    setAddCashFlag,
    addCashFlag,
    orderHistorySearchInputRef,
    paymentModal,
    handleCahInOut,
    setCashAddInFlag,
    cashAddInFlag,
    cashManagementForm,
    handleCashInOutInput,
    // Gift Card
    giftCardFlag,
    setGiftCardFlag,
    isGiftCardFlag,
    setIsGiftCardFlag,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    giftCardItems,
    setGiftCardItems,
    giftCardType,
    setGiftCardType,
    selectGiftCardItem,
    setSelectGiftCardItem,
    validateGiftCard,
    setValidateGiftCard,
    validateGiftCardForm,
    giftCardBalance,
    setGiftCardBalance,
    giftCardForm,
    CardPaymentForm,
    handleGiftCardDetails,
    handleGiftCard,
    redeemGiftCard,
    isSubmitting,
    setIsSubmitting,
    amount,
    setIsInputFocused,
    setCart,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    addToBagFlag,
    setAddToBagFlag,
    addToBagProducts,
    pickProduct,
    removeCoupon,
    isSaleTypeFlag,
    setIsSaleTypeFlag,
    showSaleType,
    setShowSaleType,
    selectedSaleType,
    setSelectedSaleType,
    selectedSaleTypeData,
    setSelectedSaleTypeData,
    openSalesRep,
    setOpenSalesRep,
    selectedProductInCart,
  } = props;
  const { t } = useTranslation();
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const [salesRepData, setSalesRepData] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState({});

  useEffect(async () => {
    await db.salesRep.toArray((res) => {
      setSalesRepData(res);
    });
  }, []);

  const renderThumbHorizontalScroll = ({ style, ...props }) => {
    const thumbStyle = {
      // backgroundColor: "#c1c1c1",
      // borderRadius: "5px",
      width: "0px",
    };
    // setLoading(false);
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Fragment>
      {/* sales rep modal start */}
      <Drawer
        closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
            <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Select Sales Rep</p>
            <img
              src={Close}
              onClick={() => {
                setSelectedSalesRep({});
                setOpenSalesRep({ flag: false, level: "" });
              }}
              style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
            />
          </div>
        }
        closeIcon={null}
        visible={openSalesRep.flag}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={() => {
          setSelectedSalesRep({});
          setOpenSalesRep({ flag: false, level: "" });
        }}
        //  id="sm-drawer-close"
        height="90vh"
      >
        <Row style={{ paddingLeft: "0.7vw", fontSize: "1vw", paddingBottom: "2vh" }}>
          <Col span={9}>
            <Input
              type="text"
              className="productSearch"
              placeholder={`Search For Sales Rep`}
              id="giftCardSearchInput"
              //   onPressEnter={() => (filteredGiftCards.length === 1 ? selectGiftCard(filteredGiftCards[0]) : null)}
              //   value={searchTerm}
              style={{
                width: "35vw",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "1vw",
                color: "#0F0718",
                borderColor: "rgba(0, 0, 0, 0.25)",
              }}
              prefix={
                <img
                  src={SearchIcon}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                    paddingLeft: "0.2vw",
                  }}
                />
              }
            />
          </Col>
        </Row>
        <Scrollbars style={{ height: "55vh", width: "100%" }} renderThumbHorizontal={renderThumbHorizontalScroll}>
          <Row gutter={[16, 16]}>
            {salesRepData?.map((res) => (
              <Col
                span={6}
                style={{
                  background: selectedSalesRep.code === res.code ? "#cae5bd" : "#fff",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0 1rem",
                  padding: "0rem 0.5rem",
                }}
                onClick={() => {
                  setSelectedSalesRep(res);
                }}
              >
                <img src={User} alt="" style={{ marginRight: "10px", height: "2.5rem", margin: "0.7rem 0 " }} />
                <span style={{ marginLeft: "10px", margin: "0.7rem 0px 0.7rem 10px", fontSize: "1vw" }}>
                  <p style={{ margin: 0, fontWeight: 500, fontSize: "1em", color: "#0F0718" }}>{res.name}</p>
                  <span style={{ fontSize: "0.8em", fontWeight: 500, color: "#0F0718" }}>{res.code}</span>
                </span>
              </Col>
            ))}
          </Row>
        </Scrollbars>
        <Row>
          <Col span={24} style={{ textAlign: "right", paddingTop: "1rem" }}>
            <Button
              style={{ backgroundColor: "#0F0718", color: "#fff", height: "7vh", width: "15vw", borderRadius: "7px" }}
              disabled={!selectedSalesRep?.code}
              onClick={() => {
                let cartObj = cart;
                if (openSalesRep.level === "order") {
                  cartObj.salesRepId = selectedSalesRep.s_salesrep_id;
                  setCart(cartObj);
                  localStorage.setItem("cartObj", JSON.stringify(cartObj));
                  setSelectedSalesRep({});
                  setOpenSalesRep({ flag: false, level: "" });
                } else if (openSalesRep.level === "line") {
                  cartObj?.items?.map((lineItem) => {
                    if (lineItem.productId === selectedProductInCart.productId && lineItem.lineId === selectedProductInCart.lineId) {
                      lineItem.salesRepId = selectedSalesRep.s_salesrep_id;
                    }
                  });
                  setCart(cartObj);
                  localStorage.setItem("cartObj", JSON.stringify(cartObj));
                  setSelectedSalesRep({});
                  setOpenSalesRep({ flag: false, level: "" });
                }
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Drawer>
      {/* sales rep modal end */}
    </Fragment>
  );
};

export default PointOfSuperMarketModals;
