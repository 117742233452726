
import React from 'react';
import { useState } from 'react';
import { Modal, Popover, Row, Col, Card, Progress, Table, Divider, Drawer, Button, Input, Menu, Spin, Badge, message, Select, DatePicker, Form, Tabs, Checkbox, Space } from "antd";
import {
  CalendarOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseOutlined,
  LoadingOutlined,
  CloseSquareFilled,
  RightOutlined,
  DownOutlined,
  EditOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import POSLogo from "../../../../assets/images/NewLogoCW.svg";
import sideMenu from "../../../../assets/images/side-menu.svg";
import LockIcon from "../../../../assets/images/lock-icon.svg";
import userIcon from "../../../../assets/images/userId.svg";
import ScanIcon from "../../../../assets/images/scanIcon.svg";
import Online from "../../../../assets/images/green.svg";
import Ofline from "../../../../assets/images/red.svg";
import Wifi from "../../../../assets/images/wifi_Icon.svg";
import LogoutIcon from "../../../../assets/images/logout_Icon.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import FilterIcon from "../../../../assets/images/filterNewICon.svg";
import DefaultProductImage from "../../../../assets/images/no-image.svg";
import styles from "../../../../constants/styles.json";
import { useHistory } from "react-router-dom";

const ProductionOrder = (props) => {
    const {
        userData,
        addProduct,
        cart,
        setCart,
        checkIsManualWeight,
        clearProductSearchResults,
        closeProductPanel,
        confirmDiscardCart,
        showAddNewCustomerFields,
        decreaseProductQty,
        deleteCart,
        giftCardData,
        setGiftCardData,
        deleteProduct,
        displayClock,
        getMoreProducts,
        getSearchedProducts,
        increaseProductQty,
        isProductsFilter,
        isQtyUpdate,
        isSearchProducts,
        loader,
        loading,
        setLoading,
        onBarcodeInput,
        openDisplayParkedBillModal,
        openPaymentModal,
        setFilterdDate,
        orderType,
        parkBill,
        parkedList,
        productCategories,
        productBrands,
        handleBrandCheckboxChange,
        setSelectedProductBrand,
        selectedProductBrand,
        selectCategotyList,
        setSelectCategotyList,
        handleCategoryCheckboxChange,
        productSearchInput,
        productsCopy,
        selectProductCategory,
        selectProductInCart,
        selectProduct,
        selectedProductInCart,
        selectedProductQty,
        selectedRowKeys,
        setSelectedRowKeys,
        selectedKeys,
        setDisplayCustomerSearch,
        setDisplayUAECustomerSearch,
        setCloseCustomerFlag,
        setDisplayOrderType,
        setIsProductsFilter,
        setQtyNumberFlag,
        filtersFlag,
        setFiltersFlag,
        setIsQtyUpdate,
        setIsSearchProducts,
        setProductSearchInput,
        showOrderHistory,
        tillData,
        setSalesHistoryType,
        openSideMenu,
        currencyType,
        setSalesRepModalOpen,
        salesRepresent,
        setSalesRepresent,
        setSalesRepresentSearchInput,
        setFilteredSalesRepresentList,
        salesReprestiveList,
        setManualDiscountInput,
        prevProductsListRef,
        prevHistoryRef,
        setManualDiscountModalVisible,
        setDisplayOfferProductSelection,
        offerProductsList,
        clearSelectedProductInCart,
        productListCardRef,
        posLogActivity,
        upsertPOSLog,
        removeCutomer,
        onChangeTotalQuantity,
        filterDrawer,
        setFilterDrawer,
        setDenaminationsKeyboard,
        denaminationsKeyboard,
        // parked Data
        displayParkedBillModal,
        closeParkedBillModal,
        handleParkedBillSearchInput,
        setFilterdParkedList,
        searchParkedBill,
        parkedBillSearchInput,
        filterdParkedList,
        discardParkedBill,
        selectParkedBill,
        selectLayAwayOrder,
        // Cash Management
        setAddCashFlag,
        setCouponInput,
        // Order History
        setOrderHistoryInput,
        searchOrderHistory,
        changeOrderHistorySearchType,
        orderHistoryInput,
        orderHistorySearchInputRef,
        orderHistoryDetails,
        setOrderHistoryDetails,
        setOrdersCopy,
        ordersCopy,
        paymentModal,
        tillDataPaymentMethods,
        requestPayment,
        setCouponModalVisible,
        paymentCardRef,
        setSelectedPaymentMethod,
        selectedPaymentMethod,
        amount,
        setAmount,
        setNumb,
        onChangeAmount,
        paymentModalInputRef,
        processOrder,
        paymentModalLoyalityMessages,
        handleAmount,
        handleCashPayment,
        customerFlag,
        setCustomerFlag,
        setOverPayedAmount,
        overPayedAmount,
        handleTotalQty,
        enterTotalQty,
        showPaymentMethods,
        setShowPaymentMethods,
        selectedProductCategory,
        setCashAddInFlag,
        addCashFlag,
        isPrintModeXML,
        setSelectedKeys,
        setDisplayReturnOrderSearch,
        displayReturnOrderSearch,
        getSearchedItem,
        isProductsVisible,
        setIsProductsVisible,
        pickProduct,
        // gigt card
        validateGiftCard,
        giftCardFlag,
        setGiftCardFlag,
        giftCardItems,
        giftCardType,
        setGiftCardType,
        isCardPaymentFlag,
        setIsCardPaymentFlag,
        setIsGiftCardFlag,
        // keyboard
        setKeyboardType,
        keyboardType,
        layout,
        setLayout,
        inputName,
        setInputName,
        keyboardParkbill,
        keyboardRef,
        keyboardProduct,
        handleKeyboardInput,
        handleKeyPress,
        searchHistoryInput,
        setSearchhistoryInput,
        showEditOldCustomerFields,
        setEditFlag,
        openPaymentModalByCustomer,
        setSelectedProductInCart,
        setParkedBillSearchInput,
        salesHistoryCustomerSearchInput,
        salesHistoryDocumentNoSearchInput,
        setSalesHistoryCustomerSearchInput,
        setSalesHistoryDocumentNoSearchInput,
        quantityInputRef,
        setIsInputFocused,
        isInputFocused,
        getCategoryProducts,
        salesHistoryType,
        setStartRowData,
        startRowData,
        cartObj,
        documentSequence,
        setDocumnetSequence,
        notesValue,
        setNotesValue,
        selectedProductForNotes,
        setSelectedProductForNotes,
        couponModalVisible,
        displayCustomerSearch,
        displayAddNewCustomer,
        displayEditOldCustomer,
        isGiftCardFlag,
        manualDiscountModalVisible,
        loyalityOtpModalVisible,
        productsData,
        orderDelay,
        setOrderDelay,
        addToBagFlag,
        setAddToBagFlag,
        setStockList,
        stockList,
        displayUAECustomer,
        setDisplayUAECustomer,
        showProductionOrder,
        setShowProductionOrder,
        formattedDateTime,
        setIsLocked,
        setEnableProduction
      } = props;
      const { Option } = Select;
      const history = useHistory();
    const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
    const posConfig = JSON.parse(localStorage.getItem("posConfig"));
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [tillLink, setTillLink] = useState(false);

    return (
        <>
        
        <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
          <Row
            style={{
              padding: "0.8% 0",
              height: "9vh",
              alignItems: "center",
              fontSize: "1vw",
            }}
           >
            <Col
              onClick={() => {
                setTillLink(true);
              }}
              style={{ cursor: "pointer" }}
              span={1}
            >
              <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
            </Col>
            {/* 7 */}
            <Col span={3}>
              <img src={ POSLogo} style={{ height: "2.5vw", paddingLeft: "0.4vw" }} />
            </Col>
            <Col span={4} style={{display: "flex", alignItems: "flex-end", height: "100%",paddingleft:"10px"}}>
             <span style={{fontSize:"1em",fontWeight:"500",fontFamily:"Inter",color:"#0F0718"}}>Production Order #1892020</span>
            </Col>
            <Col span={4} />
            <Col
              span={7}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              >
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "1em",
                  color: "#929098",
                  marginLeft: "5vw",
                }}
               >
                {" "}
                {formattedDateTime}
              </span>
            </Col>
            <Col span={1} />
            
            <Col
              span={5}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                paddingRight: "1%",
              }}
             >
              <img
                onClick={() => {
                  setIsLocked(true);
                }}
                src={LockIcon}
                style={{ height: "3vh", cursor: "pointer" }}
              />
              &nbsp; &nbsp;
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  padding: "0.5vw",
                  border: "1px solid #3C424B",
                  borderRadius: "50%",
                }}
              >
                <img src={userIcon} style={{ height: "2.7vh" }} />
              </div>
              <div
                style={{
                  marginLeft: "1vw",
                  display: "flex",
                  flexDirection: "column",
                }}
               >
                <span
                  style={{
                    color:"#0F0718",
                    fontSize: "1em",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                 >
                  {/* {userData.user} */}
                  CW Admin
                </span>
                <span
                  style={{
                    color: "#0F0718",
                    fontSize: "0.8em",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                 >
                  Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
                </span>
              </div>
              <div style={{ marginLeft: "3%" }}>
                <img
                  src={Online}
                  style={{
                    display: !isOnline && "none",
                    height: "2.5vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
                <img
                  src={Ofline}
                  style={{
                    display: isOnline && "none",
                    height: "2.5vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
                &nbsp;
                <img
                  src={Wifi}
                  style={{
                    height: "4vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
                &nbsp;
                <img
                  src={LogoutIcon}
                  style={{
                    height: "4vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
              </div>
            </Col>
          </Row>

          <div style={{ display: "flex", height: "91vh" }}>
            {/* Left side UI */}
            <div style={{ width: "63.9%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "0 0 0 1.7%",
                }}
              >
                <div style={{ width: "93%" }}>
                  <Input
                    placeholder="Search for products by code/name"
                    id="sm-product-search"
                    className="productSearch-sm"
                    style={{
                      borderRadius: "7px",
                      padding: "0.8vh 0.8vw",
                      borderColor: "#fff",
                    }}
                    suffix={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {productSearchInput.length > 0 ? (
                          <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} />
                        ) : null}
                      </div>
                    }
                    prefix={
                      <img
                        src={SearchIcon}
                        alt=""
                      />
                    }
                  />
                </div>
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "4.5%",
                    margin: "0 0.2vw 0 0.8vw",
                    borderRadius: "7px",
                    height: "inherit",
                    borderColor: "#fff",
                  }}
                >
                  <img src={ScanIcon} style={{ height: "2.7vh" }} />
                </Button>
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "4.5%",
                    margin: "0 1vw 0 0.5vw",
                    borderRadius: "7px",
                    height: "inherit",
                    borderColor: "#fff",
                  }}
                  id="sm-product-drawer"
                 >
                  <img src={FilterIcon} style={{ height: "2.7vh" }} />
                </Button>


               
              </div>

              <div style={{ padding: "1.5%" }}>
              <Table
                  className="sm-cart-table-pos"
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    border: "none",
                    borderRadius: "7px",
                    padding: "0px 7px",
                    height: "56.5vh",
                    background: "#fff",
                  }}
                  pagination={false}
                  sticky
                  id="sm-cart-table"
                  columns={[
                    {
                      title: "Item Name",
                      dataIndex: "name",
                      key: "name",
                      width: "28%",
                      
                    },
                    {
                      title: "Qty",
                      dataIndex: "weight",
                      key: "weight",
                      width: "11%",
                      align: "center",
                    },
                    {
                      title: "Discount",
                      dataIndex: "discount",
                      key: "discount",
                      width: "11%",
                      align: "center",
                    },
                    {
                      title: "Tax",
                      dataIndex: "taxAmount",
                      key: "taxAmount",
                      width: "11%",
                      align: "center",
                    },
                    {
                      title: "Price",
                      dataIndex: "realPrice",
                      key: "realPrice",
                      width: "10%",
                      align: "center",
                    },
                    {
                      title: "Total",
                      dataIndex: "nettotal",
                      key: "nettotal",
                      width: "15%",
                      align: "center",
                    },
                    {
                      title: "",
                      dataIndex: "",
                      key: "",
                      width: "8%",
                      align: "center",
                    },
                  ]}
                />
              </div>
               
              <div style={{ padding: "0 1.5% 0 1.5%", display: "flex" }}>
                <div
                  style={{
                    background:"#fff",
                    height: "23.5vh",
                    width: "60%",
                    marginRight: "1%",
                    border: "1px solid #fff",
                    borderRadius: "7px",
                    padding: "0.5vh",
                    fontSize: "1vw",
                  }}
                >
                  <Row style={{ height: "4.5vh" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      <p style={styles.screenStyles.mainScreen.subTotalText}>Subtotal:</p>
                    </Col>
                    <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                     
                      &nbsp;
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        ₹0.00
                      </p>
                      <span style={{ marginLeft: "2px", marginTop: 0 }}></span>
                    </Col>
                  </Row>

                  <Row style={{ height: "4.4vh" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      <p style={styles.screenStyles.mainScreen.subTotalText}>Discount:</p>
                    </Col>
                    <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                      
                      &nbsp;
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        0.00
                      </p>
                      <span style={{ marginLeft: "2px" }}></span>
                    </Col>
                  </Row>

                  <Row style={{ height: "4.5vh" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      <p style={styles.screenStyles.mainScreen.subTotalText}>Tax:</p>
                    </Col>
                    <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                      &nbsp;
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        0.00
                      </p>
                      <span style={{ marginLeft: "2px" }}></span>
                    </Col>
                  </Row>

                  <Row style={{ height: "4.5vh" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      <p style={styles.screenStyles.mainScreen.subTotalText}>Net Value:</p>
                    </Col>
                    <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                      &nbsp;
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        ₹0.00
                      </p>
                      <span style={{ marginLeft: "2px" }}></span>
                    </Col>
                  </Row>

                  <Row style={{ height: "4.5vh" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      <p style={styles.screenStyles.mainScreen.subTotalText}>Total Items / Total Qty:</p>
                    </Col>
                    <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        0 / 0
                      </p>
                    </Col>
                  </Row>
                </div>

                <div style={{ height: "23.7vh", width: "40%", fontSize: "1vw" }}>
                  <div
                    id="sm-cart-total"
                    // onClick={handleCartTotalClick}
                    style={{
                      height: "23.5vh",
                      position: "relative",
                      background: "#fff",
                      borderRadius: "7px",
                      cursor: "pointer",
                    }}
                   >
                    <p
                      style={{
                        padding: "0.7vw 0 0 1vw",
                        color: "#0F0718",
                        fontSize: "1em",
                        fontWeight: "500",
                        height: "4.5vh",
                      }}
                    >
                      Sale Price
                    </p>
                    
                      <p
                        style={{
                          textAlign: "center",
                          color: "#0F0718",
                          fontSize: "2.5em",
                          fontWeight: "500",
                          height: "14.4vh",
                          width: "100%",
                        }}
                      >
                        {" "}
                        ₹0
                      </p>
                  </div>
                </div>
              </div>



            </div>

              <div
                style={{
                  width: "35.6%",
                  padding: "0% 0% 0 0.4%",
                  borderRadius: "10px",
                  paddingBottom: "0",
                  height: "89.5vh",
                  marginRight: "1%",
                  backgroundColor:"#fff",
                  position: "relative", 
                }}
               >
                <div style={{paddingBottom: "7vh" }}>
              <Row>
                <Col span={6}>
                  <img src={DefaultProductImage} 
                   style={{
                    width: "100%",
                    height: "11vh",
                    // objectFit: "contain",
                    borderRadius: "5px",
                    marginTop: "5vh",
                    marginLeft:"1.5vw"
                    // alignSelf: "center",
                  }}
                  />
                </Col>
                  <Col span={2} />
                  <Col span={9} style={{marginTop:"5vh"}}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "1.5em", fontWeight: 500, color: "#0F0718" }}>
                    Chocolate Tray
                    </span>
                    <span style={{ fontSize: "1.2em", fontWeight: 400, color: "#0F0718" }}>
                    Qty 1
                    </span>
                  </div>
                  </Col>
              </Row>

              <Row >
                <Col span={8} style={{marginLeft:"1.5vw",marginTop:"6vh"}}>
                  <p style={{fontWeight:400,fontWeight:"1em",color:"#0F0718"}}>Production Type</p>
                </Col>
                  <Col span={1} />
                <Col span={11} style={{marginTop:"5vh"}}>
                <Select className="selectType" style={{border: "1px solid #d9d9d9",width:"18vw"}} placeholder={<span className="cartSelect" style={{fontSize:"1em",width:"15vw"}}>Select</span>}>
                        <Option  key="VI" value="VI">
                          Select
                        </Option>
                </Select>
                </Col>
              </Row>

              <Row >
                <Col span={8} style={{marginLeft:"1.5vw",marginTop:"2vh"}}>
                  <p style={{fontWeight:400,fontWeight:"1em",color:"#0F0718"}}>Production Date</p>
                </Col>
                  <Col span={1} />
                <Col span={9} style={{marginTop:"2vh"}}>
                <p style={{fontWeight:500,color:"#0F0718",fontSize:"1em"}}>02/09/2024</p>
                </Col>
              </Row>

              <Row >
                <Col span={8} style={{marginLeft:"1.5vw",marginTop:"2vh"}}>
                  <p style={{fontWeight:400,fontWeight:"1em",color:"#0F0718"}}>Batch</p>
                </Col>
                  <Col span={1} />
                <Col span={9} style={{marginTop:"2vh"}}>
                <p style={{fontWeight:500,color:"#0F0718",fontSize:"1em"}}>78012345</p>
                </Col>
              </Row>

              <Row >
                <Col span={8} style={{marginLeft:"1.5vw",marginTop:"2vh"}}>
                  <p style={{fontWeight:400,fontWeight:"1em",color:"#0F0718"}}>Markup Price</p>
                </Col>
                  <Col span={1} />
                <Col span={9} style={{marginTop:"2vh"}}>
                <Input style={{width:"18vw",height:"5vh"}}
                //  suffix={<FilterOutlined style={{ cursor: 'pointer' }} />}
                suffix={<EditOutlined style={{ cursor: 'pointer' }} />}
                />
                </Col>
              </Row>

              <Row >
                <Col span={8} style={{marginLeft:"1.5vw",marginTop:"2vh"}}>
                  <p style={{fontWeight:400,fontWeight:"1em",color:"#0F0718"}}>Remarks</p>
                </Col>
                  <Col span={1} />
                <Col span={13} style={{marginTop:"2vh"}}>
                <TextArea 
                  style={{width:"18vw",resize: "none", }}
                
                 >

                </TextArea>
                </Col>
              </Row>

              <Button 
                style={{
                  color:"#fff",
                  width:"95%",
                  fontWeight:500,
                  borderRadius:"5px",
                  background:"#2F3856",
                  fontSize:"1.4em",
                  height:"7vh",
                  position: "absolute", 
                  bottom: "10px",
                  left: "50%",
                  transform: "translateX(-50%)" 
                }}
               >
                   Process
              </Button>
                </div>
               </div>
              
              </div>              
            </div>
        </>
    );
};

export default ProductionOrder;
